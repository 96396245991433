export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export function formatTime(time, option) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
  }
}

export const delEmptyQueryNodes = (obj = {}) => {
  Object.keys(obj).forEach(key => {
    let value = obj[key]
    value && typeof value === 'object' && delEmptyQueryNodes(value)

    if (value === '' || value === null || value === undefined) {
      // || Object.keys(value).length === 0 || value.length === 0
      delete obj[key]
    }
  })
  return obj
}

/**
 * 判断变量是否为空
 * @param {*} obj
 * @returns
 */
export const isEmpty = obj => {
  if (typeof obj == 'undefined' || obj == null || (typeof obj == 'string' && obj.trim() == '') || (typeof obj == 'object' && obj.length == 0)) {
    return true
  } else {
    return false
  }
}

// 年月日
export function dateFormat() {
  let date = new Date()
  let y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m >= 10 ? m : '0' + m
  let d = date.getDate()
  d = d >= 10 ? d : '0' + d
  let h = date.getHours()
  h = h >= 10 ? h : '0' + h
  let i = date.getMinutes()
  i = i >= 10 ? i : '0' + i
  let s = date.getSeconds()
  s = s >= 10 ? s : '0' + s

  return String(y) + m + d + h + i + s
}
