import Vue from 'vue'
import App from './App.vue'
import {router} from './router/index'
import store from '@/store/index'
import registerGlobalComponents from './plugins/register-components'
import '@/styles/index.scss' // global css
import '@/plugins/index.js'


import ElementUI from 'element-ui'
ElementUI.Dialog.props.closeOnClickModal.default = false
import 'element-ui/lib/theme-chalk/index.css'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
Vue.use(ElementUI, { zIndex: 3000 })
registerGlobalComponents()
Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    beforeCreate() {
        Vue.prototype.$bus = this
    },
    router,
    store
}).$mount('#app')
